// axios mais questoes
import axios from "axios";
import btoa from "btoa";

const axiosMQ = axios.create({
  baseURL: "https://api.maisquestoes.com.br",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic " + btoa("admin:admin"),
  },
});

export default axiosMQ;
