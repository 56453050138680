<template>
  <div>
    <b-form @submit.prevent="submitCreate" enctype="multipart/form-data">
      <b-row class="form-row">
        <b-col md="8">
          <b-card header="Informações básicas" class="mb-1">
            <b-card-text>
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      <i class="text-danger bi bi-record-circle"></i>
                      Título
                    </label>

                    <b-form-input
                      v-model="item.title"
                      placeholder="Título"
                      :class="{ 'is-invalid': $v.item.title.$error }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card header="Configurações" class="mb-1">
            <b-card-text>
              <b-row class="form-row">
                <b-col md="12">
                  <b-form-group>
                    <label>Edital</label>
                    <v-select
                      label="name"
                      item-text="name"
                      item-value="id"
                      v-model="item.api_questoes_edital"
                      placeholder="Edital"
                      :options="optionsContests"
                      @search="filterContests"
                      @input="setDays"
                    >
                      <template #option="data">
                        <div>
                          <span>{{ data.name }}</span>
                          <br />
                          <small class="text-muted">{{ data.id }}</small>
                        </div>
                      </template>
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>Dias</label>
                    <b-form-input
                      type="number"
                      placeholder="Edital relacionado"
                      v-model="item.days"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card
            header="Produtos relacionados"
            header-class="mb-1"
            class="mb-1"
          >
            <b-tabs>
              <b-tab title="Curso Online" @click="changeProductType(2)">
                <div class="form-row">
                  <div class="col-md-9">
                    <p class="mb-0 pt-1">
                      Você pode ordenar arrastando os produtos listados abaixo.
                    </p>
                  </div>
                  <div class="col-md-3">
                    <b-button
                      v-b-modal.modal-product
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      block
                      variant="flat-primary"
                    >
                      <feather-icon icon="PlusCircleIcon" /> adicionar
                    </b-button>
                  </div>
                </div>
                <draggable
                  tag="ul"
                  :list="this.item.products.online"
                  class="list-group mt-1"
                  handle=".handle"
                >
                  <li
                    v-for="(element, index) in this.item.products.online"
                    :key="index"
                    class="list-group-item"
                  >
                    <feather-icon icon="MoveIcon" class="handle" size="15" />

                    <span class="pl-1 text-not-selected"
                      >{{ element.title }}
                    </span>

                    <feather-icon
                      icon="XCircleIcon"
                      class="float-right"
                      size="20"
                      @click="removeAt(2, index)"
                    />
                  </li>
                </draggable>
              </b-tab>
              <b-tab title="Curso Presencial" @click="changeProductType(1)">
                <div class="form-row">
                  <div class="col-md-9">
                    <p class="mb-0 pt-1">
                      Você pode ordenar arrastando os produtos listados abaixo.
                    </p>
                  </div>
                  <div class="col-md-3">
                    <b-button
                      v-b-modal.modal-product
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      block
                      variant="flat-primary"
                    >
                      <feather-icon icon="PlusCircleIcon" /> adicionar
                    </b-button>
                  </div>
                </div>
                <draggable
                  tag="ul"
                  :list="this.item.products.presencial"
                  class="list-group mt-1"
                  handle=".handle"
                >
                  <li
                    v-for="(element, index) in this.item.products.presencial"
                    :key="index"
                    class="list-group-item"
                  >
                    <feather-icon icon="MoveIcon" class="handle" size="15" />

                    <span class="pl-1 text-not-selected"
                      >{{ element.title }}
                    </span>

                    <feather-icon
                      icon="XCircleIcon"
                      class="float-right"
                      size="20"
                      @click="removeAt(1, index)"
                    />
                  </li>
                </draggable>
              </b-tab>
              <b-tab title="Live" @click="changeProductType(3)">
                <div class="form-row">
                  <div class="col-md-9">
                    <p class="mb-0 pt-1">
                      Você pode ordenar arrastando os produtos listados abaixo.
                    </p>
                  </div>
                  <div class="col-md-3">
                    <b-button
                      v-b-modal.modal-product
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      block
                      variant="flat-primary"
                    >
                      <feather-icon icon="PlusCircleIcon" /> adicionar
                    </b-button>
                  </div>
                </div>
                <draggable
                  tag="ul"
                  :list="this.item.products.live"
                  class="list-group mt-1"
                  handle=".handle"
                >
                  <li
                    v-for="(element, index) in this.item.products.live"
                    :key="index"
                    class="list-group-item"
                  >
                    <feather-icon icon="MoveIcon" class="handle" size="15" />

                    <span class="pl-1 text-not-selected"
                      >{{ element.title }}
                    </span>

                    <feather-icon
                      icon="XCircleIcon"
                      class="float-right"
                      size="20"
                      @click="removeAt(3, index)"
                    />
                  </li>
                </draggable>
              </b-tab>
              <b-tab title="Apostilas" @click="changeProductType(6)">
                <div class="form-row">
                  <div class="col-md-9">
                    <p class="mb-0 pt-1">
                      Você pode ordenar arrastando os produtos listados abaixo.
                    </p>
                  </div>
                  <div class="col-md-3">
                    <b-button
                      v-b-modal.modal-product
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      block
                      variant="flat-primary"
                    >
                      <feather-icon icon="PlusCircleIcon" /> adicionar
                    </b-button>
                  </div>
                </div>
                <draggable
                  tag="ul"
                  :list="this.item.products.book"
                  class="list-group mt-1"
                  handle=".handle"
                >
                  <li
                    v-for="(element, index) in this.item.products.book"
                    :key="index"
                    class="list-group-item"
                  >
                    <feather-icon icon="MoveIcon" class="handle" size="15" />

                    <span class="pl-1 text-not-selected"
                      >{{ element.title }}
                    </span>
                    <feather-icon
                      icon="XCircleIcon"
                      class="float-right"
                      size="20"
                      @click="removeAt(6, index)"
                    />
                  </li>
                </draggable>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>

        <b-col md="4">
          <b-card header="Imagem de destaque" class="mb-1">
            <b-card-text>
              <b-row class="form-row">
                <b-col md="12">
                  <input
                    id="image"
                    type="file"
                    accept="image/*"
                    class="form-control-file hidden"
                    @change="previewImage"
                  />
                  <b-form-group>
                    <label
                      for="image"
                      class="d-block text-center"
                      style="
                        border: 1px solid rgb(222 222 222);
                        border-radius: 4px;
                        border-style: dashed;
                        cursor: pointer;
                        font-size: 1.1rem;
                      "
                    >
                      <div v-if="!preview" class="pt-5 py-4">
                        <feather-icon size="19" icon="ImageIcon" />
                        Selecionar imagem
                      </div>
                      <template v-if="preview">
                        <b-img
                          for="image"
                          :src="preview"
                          style="width: 100%"
                          fluid
                        />
                      </template>
                    </label>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label>
                    <i class="text-danger bi bi-record-circle"></i>
                    Label da imagem
                  </label>
                  <b-form-group class="mb-0">
                    <b-form-input v-model="item.label_image" type="text" />
                  </b-form-group>
                  <small
                    class="d-block"
                    style="margin-top: 4px; font-size: 0.9rem"
                  >
                    Será exibido sobre a imagem de destaque.
                  </small>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card header="Detalhes" header-class="mb-1" class="mb-1">
            <b-card-text>
              <div class="form-row">
                <div class="col-md-12 col-12">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Tag(s)
                  </label>
                  <div class="form-group mb-0">
                    <b-form-tags
                      v-model="item.tags"
                      separator=",;"
                      no-add-on-enter
                      class="form-control"
                      placeholder=""
                      :class="{ 'is-invalid': $v.item.tags.$error }"
                    />
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>

          <b-card header="Opções" class="mb-1">
            <b-card-text>
              <b-row>
                <b-col md="12">
                  <hr />
                  <label> Status </label>
                  <v-select
                    placeholder=""
                    v-model="item.status"
                    label="title"
                    item-text="title"
                    item-value="code"
                    :options="[
                      { title: 'Ativo', code: 1 },
                      { title: 'Inativo', code: 0 },
                    ]"
                  />
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <ButtonsActionsFooter
            routerBack="contests-list"
            variant="success"
            :submited="submited"
            :text="isEditing ? 'Atualizar' : 'Cadastrar'"
            subtext="Aguarde..."
          />
        </b-col>
      </b-row>
    </b-form>

    <b-modal
      id="modal-product"
      centered
      title="Vincular Produto"
      cancel-title="Fechar"
      hide-footer
    >
      <b-form>
        <b-card-text>
          <b-row class="form-row">
            <b-col md="12">
              <b-form-group>
                <label> Digite o título do produto </label>
                <v-select
                  ref="selectProduct"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="product"
                  placeholder="Digite o título"
                  :options="optionsProducts"
                  @search="fetchProducts"
                  @input="productSelected"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectProduct.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>
            </b-col>
            <div class="col-12" style="min-height: 250px">
              <p>Produtos selecionados:</p>
              <draggable
                v-if="productTypeId === 1"
                tag="ul"
                :list="item.products.presencial"
                class="list-group mt-1"
                handle=".handle"
              >
                <li
                  v-for="(element, idx) in item.products.presencial"
                  :key="element.code"
                  class="list-group-item"
                >
                  <feather-icon icon="MoveIcon" class="handle" size="15" />

                  <span class="pl-1 text-not-selected"
                    >{{ element.title }}
                  </span>

                  <feather-icon
                    icon="XCircleIcon"
                    class="float-right"
                    size="20"
                    @click="removeAt(1, idx)"
                  />
                </li>
              </draggable>

              <draggable
                v-if="productTypeId === 2"
                tag="ul"
                :list="item.products.online"
                class="list-group mt-1"
                handle=".handle"
              >
                <li
                  v-for="(element, idx) in item.products.online"
                  :key="element.code"
                  class="list-group-item"
                >
                  <feather-icon icon="MoveIcon" class="handle" size="15" />

                  <span class="pl-1 text-not-selected"
                    >{{ element.title }}
                  </span>

                  <feather-icon
                    icon="XCircleIcon"
                    class="float-right"
                    size="20"
                    @click="removeAt(2, idx)"
                  />
                </li>
              </draggable>

              <draggable
                v-if="productTypeId === 3"
                tag="ul"
                :list="item.products.live"
                class="list-group mt-1"
                handle=".handle"
              >
                <li
                  v-for="(element, idx) in item.products.live"
                  :key="element.code"
                  class="list-group-item"
                >
                  <feather-icon icon="MoveIcon" class="handle" size="15" />

                  <span class="pl-1 text-not-selected"
                    >{{ element.title }}
                  </span>

                  <feather-icon
                    icon="XCircleIcon"
                    class="float-right"
                    size="20"
                    @click="removeAt(3, idx)"
                  />
                </li>
              </draggable>

              <draggable
                v-if="productTypeId === 6"
                tag="ul"
                :list="item.products.book"
                class="list-group mt-1"
                handle=".handle"
              >
                <li
                  v-for="(element, idx) in item.products.book"
                  :key="element.code"
                  class="list-group-item"
                >
                  <feather-icon icon="MoveIcon" class="handle" size="15" />

                  <span class="pl-1 text-not-selected"
                    >{{ element.title }}
                  </span>

                  <feather-icon
                    icon="XCircleIcon"
                    class="float-right"
                    size="20"
                    @click="removeAt(6, idx)"
                  />
                </li>
              </draggable>
            </div>
          </b-row>
        </b-card-text>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BTabs,
  BTab,
  BImg,
  BCard,
  BFormTags,
  VBTooltip,
  BInputGroup,
  BCollapse,
  VBToggle,
  BButton,
  BFormDatepicker,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import axiosMQ from "@/libs/axios_mq";

export default {
  components: {
    BCard,
    ButtonsActionsFooter,
    BFormDatepicker,
    BFormCheckbox,
    BCollapse,
    BImg,
    BInputGroup,
    BCardText,
    BTabs,
    BTab,
    draggable,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BFormTags,
    BButton,
  },
  order: 1,
  data() {
    return {
      uuid: null,
      loading: false,
      isEditing: false,
      submited: false,
      productTypeId: 2,
      userData: "",
      school: null,
      item: {
        title: "",
        api_questoes_edital: "",
        api_questoes_area: "",
        status: { title: "Ativo", code: 1 },
        tags: [],
        price: 0,
        sale: 0,
        products: {
          online: [],
          presencial: [],
          live: [],
          book: [],
        },
        image: null,
        label_image: "",
      },
      product: "",
      preview: null,
      image: null,
      optionsProducts: [],
      optionsContests: [],
      optionsContestsComplete: [],
    };
  },
  async created() {
    this.loading = true;
    this.uuid = this.$route.params.uuid;

    if (this.uuid) {
      this.isEditing = true;
      await this.getData();
    }

    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.school =
      this.userData.school.domain === "degraucultural.com.br" ? 1 : 2;

    await this.fetchContests();
    this.loading = false;
  },
  methods: {
    async fetchContests() {
      const contests = await axiosMQ.get("/contests", {
        params: {
          max_results: 1000,
          where: JSON.stringify({
            area: this.school === 1 ? "degrau" : "centralconcursos",
          }),
        },
        headers: {
          "x-org-id": "eduqc",
        },
      });

      if (contests && contests.data && contests.data._items) {
        this.optionsContests = contests.data._items.map((element) => {
          return {
            name:
              element.institution +
              " - " +
              element.test.name +
              " - " +
              element.test.year,
            id: element._id,
            days: element.days,
          };
        });

        this.optionsContestsComplete = this.optionsContests;
      }
    },
    filterContests(term) {
      if (term && term.length > 2) {
        this.optionsContests = this.optionsContests.filter((element) => {
          return element.name.toLowerCase().indexOf(term.toLowerCase()) > -1;
        });
      } else {
        this.optionsContests = this.optionsContestsComplete;
      }
    },
    async getData() {
      this.$store
        .dispatch("Product/show", this.$route.params.uuid)
        .then((data) => {
          axiosMQ
            .get(`/contests/${data.api_questoes_edital}`, {
              headers: {
                "x-org-id": "eduqc",
              },
            })
            .then((res) => {
              this.item.api_questoes_edital = {
                id: res.data._id,
                name:
                  res.data.institution +
                  " - " +
                  res.data.test.name +
                  " - " +
                  res.data.test.year,
                days: res.data.days,
              };
            });
          this.item = data;
          this.item.tags = this.item.tags;
          this.image_url = this.item.image_url;
          this.item.days = this.item.api_questoes_dias;
          if (data.related && data.related.length > 0) {
            this.setRelatedProducts(data.related);
          }
        });
    },
    setRelatedProducts(products) {
      const item = {
        online: [],
        presencial: [],
        live: [],
        book: [],
      };

      products.forEach((product) => {
        switch (product.category_id) {
          case 1:
            item.presencial.push({
              code: product.code,
              title: product.title,
              product_id: product.product_id,
            });
            break;
          case 2:
            item.online.push({
              code: product.code,
              title: product.title,
              product_id: product.product_id,
            });
            break;
          case 3:
            item.live.push({
              code: product.code,
              title: product.title,
              product_id: product.product_id,
            });
            break;
          case 6:
            item.book.push({
              code: product.code,
              title: product.title,
              product_id: product.product_id,
            });
            break;
          default:
            break;
        }
      });

      this.item.products = item;
    },
    setDays() {
      if (this.item.contest) {
        this.item.days = this.item.contest.days;
      }
    },
    productSelected(event) {
      if (event) {
        if (
          Array.isArray(this.item.products) &&
          this.item.products.length === 0
        ) {
          this.item.products = {
            online: [],
            presencial: [],
            live: [],
            book: [],
          };
        }
        this.optionsProducts = "";
        this.product = "";

        switch (event.product_category_id) {
          case 1:
            if (
              !this.item.products.presencial.find(
                (element) => element.code === event.code
              )
            ) {
              this.item.products.presencial.push({
                code: event.code,
                product_id: event.product_id,
                title: event.title,
              });
            }
            break;
          case 2:
            if (
              !this.item.products.online.find(
                (element) => element.code === event.code
              )
            ) {
              this.item.products.online.push({
                code: event.code,
                product_id: event.product_id,
                title: event.title,
              });
            }

            break;
          case 3:
            if (
              !this.item.products.live.find(
                (element) => element.code === event.code
              )
            ) {
              this.item.products.live.push({
                code: event.code,
                product_id: event.product_id,
                title: event.title,
              });
            }
            break;
          default:
            if (
              !this.item.products.book.find(
                (element) => element.code === event.code
              )
            ) {
              this.item.products.book.push({
                code: event.code,
                product_id: event.product_id,
                title: event.title,
              });
            }
            break;
        }
      }
    },
    changeProductType(event) {
      this.productTypeId = event;
    },
    async fetchProducts(term) {
      this.optionsProducts = [];
      if (term.length > 2) {
        this.$store
          .dispatch("Product/search", { term: term, type: this.productTypeId })
          .then((res) => {
            this.optionsProducts = res;
          });
      }
    },
    removeAt(type, idx) {
      switch (type) {
        case 1:
          this.item.products.presencial.splice(idx, 1);
          break;
        case 2:
          this.item.products.online.splice(idx, 1);
          break;
        case 3:
          this.item.products.live.splice(idx, 1);
          break;
        default:
          this.item.products.book.splice(idx, 1);
          break;
      }

      this.item.products.book.splice(idx, 1);
    },
    previewImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        const file = input.files[0];
        this.item.image = file;
        reader.readAsDataURL(file);
      }
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        const formData = new FormData();
        formData.append("title", this.item.title);
        formData.append(
          "api_questoes_edital",
          this.item.api_questoes_edital.id ?? ""
        );
        formData.append(
          "api_questoes_area",
          this.school === 1 ? "degrau" : "central"
        );
        formData.append("api_questoes_dias", this.item.days ?? 30);
        formData.append("tags", JSON.stringify(this.item.tags));
        formData.append(
          "status",
          this.item.status ? this.item.status.code : ""
        );
        formData.append("products", JSON.stringify(this.item.products));
        formData.append(
          "product_category_uuid",
          process.env.VUE_APP_PRODUCT_CATEGORY_COMBO_UUID
        );
        formData.append("image", this.item.image);
        formData.append("label_image", this.item.label_image);

        if (this.isEditing) {
          this.$store
            .dispatch("Product/update", { uuid: this.uuid, data: formData })
            .then(() => {
              this.$router.push({ name: "products-combo-list" });
              this.notifyDefault("success");
            })
            .catch((err) => {
              this.submited = false;
              this.notifyDefault("error");
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("Product/create", formData)
            .then(() => {
              this.$router.push({ name: "products-combo-list" });
              this.notifyDefault("success");
            })
            .catch((err) => {
              this.submited = false;
              this.notifyDefault("error");
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  validations: {
    item: {
      title: {
        required,
      },

      tags: {
        required,
      },
      status: {
        required,
      },
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  computed: {
    ...mapState("Product", ["searchProductsPresencial", "searchProductsLive"]),
    areaSelected() {
      return this.item.api_questoes_area;
    },
  },
};
</script>

<style>
.b-form-datepicker label {
  line-height: 0;
}
.subtitle-quill .ql-container .ql-editor,
.subtitle-quill .ql-container {
  height: 100px !important;
  min-height: 100px !important;
}
</style>
