var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        {
          attrs: { enctype: "multipart/form-data" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitCreate.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "form-row" },
            [
              _c(
                "b-col",
                { attrs: { md: "8" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-1",
                      attrs: { header: "Informações básicas" },
                    },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", [
                                        _c("i", {
                                          staticClass:
                                            "text-danger bi bi-record-circle",
                                        }),
                                        _vm._v(" Título "),
                                      ]),
                                      _c("b-form-input", {
                                        class: {
                                          "is-invalid":
                                            _vm.$v.item.title.$error,
                                        },
                                        attrs: { placeholder: "Título" },
                                        model: {
                                          value: _vm.item.title,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "title", $$v)
                                          },
                                          expression: "item.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { header: "Configurações" } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-row",
                            { staticClass: "form-row" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", [_vm._v("Edital")]),
                                      _c(
                                        "v-select",
                                        {
                                          attrs: {
                                            label: "name",
                                            "item-text": "name",
                                            "item-value": "id",
                                            placeholder: "Edital",
                                            options: _vm.optionsContests,
                                          },
                                          on: {
                                            search: _vm.filterContests,
                                            input: _vm.setDays,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "option",
                                              fn: function (data) {
                                                return [
                                                  _c("div", [
                                                    _c("span", [
                                                      _vm._v(_vm._s(data.name)),
                                                    ]),
                                                    _c("br"),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-muted",
                                                      },
                                                      [_vm._v(_vm._s(data.id))]
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.item.api_questoes_edital,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item,
                                                "api_questoes_edital",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.api_questoes_edital",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "no-options" },
                                              slot: "no-options",
                                            },
                                            [
                                              _vm._v(
                                                " Nenhum registro encontrado "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", [_vm._v("Dias")]),
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "number",
                                          placeholder: "Edital relacionado",
                                        },
                                        model: {
                                          value: _vm.item.days,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "days", $$v)
                                          },
                                          expression: "item.days",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-1",
                      attrs: {
                        header: "Produtos relacionados",
                        "header-class": "mb-1",
                      },
                    },
                    [
                      _c(
                        "b-tabs",
                        [
                          _c(
                            "b-tab",
                            {
                              attrs: { title: "Curso Online" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeProductType(2)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-md-9" }, [
                                  _c("p", { staticClass: "mb-0 pt-1" }, [
                                    _vm._v(
                                      " Você pode ordenar arrastando os produtos listados abaixo. "
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-3" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.modal-product",
                                            modifiers: {
                                              "modal-product": true,
                                            },
                                          },
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(113, 102, 240, 0.15)",
                                            expression:
                                              "'rgba(113, 102, 240, 0.15)'",
                                            modifiers: { 400: true },
                                          },
                                        ],
                                        attrs: {
                                          block: "",
                                          variant: "flat-primary",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "PlusCircleIcon" },
                                        }),
                                        _vm._v(" adicionar "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: this.item.products.online,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  this.item.products.online,
                                  function (element, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(2, index)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tab",
                            {
                              attrs: { title: "Curso Presencial" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeProductType(1)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-md-9" }, [
                                  _c("p", { staticClass: "mb-0 pt-1" }, [
                                    _vm._v(
                                      " Você pode ordenar arrastando os produtos listados abaixo. "
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-3" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.modal-product",
                                            modifiers: {
                                              "modal-product": true,
                                            },
                                          },
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(113, 102, 240, 0.15)",
                                            expression:
                                              "'rgba(113, 102, 240, 0.15)'",
                                            modifiers: { 400: true },
                                          },
                                        ],
                                        attrs: {
                                          block: "",
                                          variant: "flat-primary",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "PlusCircleIcon" },
                                        }),
                                        _vm._v(" adicionar "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: this.item.products.presencial,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  this.item.products.presencial,
                                  function (element, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(1, index)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tab",
                            {
                              attrs: { title: "Live" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeProductType(3)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-md-9" }, [
                                  _c("p", { staticClass: "mb-0 pt-1" }, [
                                    _vm._v(
                                      " Você pode ordenar arrastando os produtos listados abaixo. "
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-3" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.modal-product",
                                            modifiers: {
                                              "modal-product": true,
                                            },
                                          },
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(113, 102, 240, 0.15)",
                                            expression:
                                              "'rgba(113, 102, 240, 0.15)'",
                                            modifiers: { 400: true },
                                          },
                                        ],
                                        attrs: {
                                          block: "",
                                          variant: "flat-primary",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "PlusCircleIcon" },
                                        }),
                                        _vm._v(" adicionar "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: this.item.products.live,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  this.item.products.live,
                                  function (element, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(3, index)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tab",
                            {
                              attrs: { title: "Apostilas" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeProductType(6)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-md-9" }, [
                                  _c("p", { staticClass: "mb-0 pt-1" }, [
                                    _vm._v(
                                      " Você pode ordenar arrastando os produtos listados abaixo. "
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-3" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.modal-product",
                                            modifiers: {
                                              "modal-product": true,
                                            },
                                          },
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(113, 102, 240, 0.15)",
                                            expression:
                                              "'rgba(113, 102, 240, 0.15)'",
                                            modifiers: { 400: true },
                                          },
                                        ],
                                        attrs: {
                                          block: "",
                                          variant: "flat-primary",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "PlusCircleIcon" },
                                        }),
                                        _vm._v(" adicionar "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: this.item.products.book,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  this.item.products.book,
                                  function (element, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(6, index)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-1",
                      attrs: { header: "Imagem de destaque" },
                    },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-row",
                            { staticClass: "form-row" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("input", {
                                    staticClass: "form-control-file hidden",
                                    attrs: {
                                      id: "image",
                                      type: "file",
                                      accept: "image/*",
                                    },
                                    on: { change: _vm.previewImage },
                                  }),
                                  _c("b-form-group", [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "d-block text-center",
                                        staticStyle: {
                                          border: "1px solid rgb(222 222 222)",
                                          "border-radius": "4px",
                                          "border-style": "dashed",
                                          cursor: "pointer",
                                          "font-size": "1.1rem",
                                        },
                                        attrs: { for: "image" },
                                      },
                                      [
                                        !_vm.preview
                                          ? _c(
                                              "div",
                                              { staticClass: "pt-5 py-4" },
                                              [
                                                _c("feather-icon", {
                                                  attrs: {
                                                    size: "19",
                                                    icon: "ImageIcon",
                                                  },
                                                }),
                                                _vm._v(" Selecionar imagem "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.preview
                                          ? [
                                              _c("b-img", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  for: "image",
                                                  src: _vm.preview,
                                                  fluid: "",
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("label", [
                                    _c("i", {
                                      staticClass:
                                        "text-danger bi bi-record-circle",
                                    }),
                                    _vm._v(" Label da imagem "),
                                  ]),
                                  _c(
                                    "b-form-group",
                                    { staticClass: "mb-0" },
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.item.label_image,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "label_image",
                                              $$v
                                            )
                                          },
                                          expression: "item.label_image",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "small",
                                    {
                                      staticClass: "d-block",
                                      staticStyle: {
                                        "margin-top": "4px",
                                        "font-size": "0.9rem",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Será exibido sobre a imagem de destaque. "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-1",
                      attrs: { header: "Detalhes", "header-class": "mb-1" },
                    },
                    [
                      _c("b-card-text", [
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-md-12 col-12" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                              _vm._v(" Tag(s) "),
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-group mb-0" },
                              [
                                _c("b-form-tags", {
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid": _vm.$v.item.tags.$error,
                                  },
                                  attrs: {
                                    separator: ",;",
                                    "no-add-on-enter": "",
                                    placeholder: "",
                                  },
                                  model: {
                                    value: _vm.item.tags,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "tags", $$v)
                                    },
                                    expression: "item.tags",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { header: "Opções" } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("hr"),
                                  _c("label", [_vm._v(" Status ")]),
                                  _c("v-select", {
                                    attrs: {
                                      placeholder: "",
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      options: [
                                        { title: "Ativo", code: 1 },
                                        { title: "Inativo", code: 0 },
                                      ],
                                    },
                                    model: {
                                      value: _vm.item.status,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "status", $$v)
                                      },
                                      expression: "item.status",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("ButtonsActionsFooter", {
                    attrs: {
                      routerBack: "contests-list",
                      variant: "success",
                      submited: _vm.submited,
                      text: _vm.isEditing ? "Atualizar" : "Cadastrar",
                      subtext: "Aguarde...",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-product",
            centered: "",
            title: "Vincular Produto",
            "cancel-title": "Fechar",
            "hide-footer": "",
          },
        },
        [
          _c(
            "b-form",
            [
              _c(
                "b-card-text",
                [
                  _c(
                    "b-row",
                    { staticClass: "form-row" },
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", [
                                _vm._v(" Digite o título do produto "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  ref: "selectProduct",
                                  attrs: {
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o título",
                                    options: _vm.optionsProducts,
                                  },
                                  on: {
                                    search: _vm.fetchProducts,
                                    input: _vm.productSelected,
                                  },
                                  model: {
                                    value: _vm.product,
                                    callback: function ($$v) {
                                      _vm.product = $$v
                                    },
                                    expression: "product",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      on: {
                                        click: function ($event) {
                                          _vm.$refs.selectProduct.open = false
                                        },
                                      },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-12",
                          staticStyle: { "min-height": "250px" },
                        },
                        [
                          _c("p", [_vm._v("Produtos selecionados:")]),
                          _vm.productTypeId === 1
                            ? _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: _vm.item.products.presencial,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  _vm.item.products.presencial,
                                  function (element, idx) {
                                    return _c(
                                      "li",
                                      {
                                        key: element.code,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(1, idx)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm.productTypeId === 2
                            ? _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: _vm.item.products.online,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  _vm.item.products.online,
                                  function (element, idx) {
                                    return _c(
                                      "li",
                                      {
                                        key: element.code,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(2, idx)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm.productTypeId === 3
                            ? _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: _vm.item.products.live,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  _vm.item.products.live,
                                  function (element, idx) {
                                    return _c(
                                      "li",
                                      {
                                        key: element.code,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(3, idx)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm.productTypeId === 6
                            ? _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: _vm.item.products.book,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  _vm.item.products.book,
                                  function (element, idx) {
                                    return _c(
                                      "li",
                                      {
                                        key: element.code,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(6, idx)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }